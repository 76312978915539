var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"item pingshenmenu",class:_vm.topClass == 0 ? 'bottom-border' : '',on:{"click":function($event){return _vm.changeMode('review')}}},[_vm._v(" "+_vm._s(_vm.$t("changeRequest.needReview"))+" ")]),_c('div',{staticClass:"item pingshenmenu",class:_vm.topClass == 1 ? 'bottom-border' : '',on:{"click":function($event){return _vm.changeMode('confirm')}}},[_vm._v(" "+_vm._s(_vm.$t("changeRequest.needConfirm"))+" ")]),_c('div',{staticClass:"item pingshenmenu",class:_vm.topClass == 2 ? 'bottom-border' : '',on:{"click":function($event){return _vm.changeMode('issue')}}},[_vm._v(" "+_vm._s(_vm.$t("changeRequest.myIssue"))+" ")]),_c('div',{staticClass:"item pingshenmenu",class:_vm.topClass == 3 ? 'bottom-border' : '',on:{"click":function($event){return _vm.changeMode('all')}}},[_vm._v(" "+_vm._s(_vm.$t("changeRequest.allChange"))+" ")]),_c('i',{staticClass:"iconfont",class:_vm.is_show_chart ? 'icon-chart_on' : 'icon-chart',staticStyle:{"position":"relative","right":"0.082rem","color":"rgb(48, 100, 143)","top":"6px","font-size":"16px","cursor":"pointer"},on:{"click":_vm.show_chart}})]),_c('el-table',{directives:[{name:"show",rawName:"v-show",value:((_vm.mode === 'review') & !_vm.is_show_chart),expression:"(mode === 'review') & !is_show_chart"},{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticStyle:{"width":"100%","background":"white"},attrs:{"height":"80vh","data":_vm.currentReviewData,"header-cell-style":{
      color: '#383838',
    },"cell-style":_vm.changeCellStyle},on:{"sort-change":_vm.hadleChangeRowreview}},[(_vm.mode === 'review')?_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('changeRequest.title'),"min-width":480,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.jumpToRequestDetail(scope.row)}}},[_vm._v(_vm._s(scope.row.name))])]}}],null,false,3448655335)}):_vm._e(),(_vm.mode === 'review')?_c('el-table-column',{attrs:{"prop":"createdBy","label":_vm.$t('addMyColla.table.title3'),"min-width":280},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"display":"flex","align-items":"center","gap":"10px"}},[_c('personal-avatar',{staticStyle:{"justify-content":"left"},attrs:{"placement":'right',"size":33,"avatar":(_vm.getUser(scope.row.createdBy) &&
                _vm.getUser(scope.row.createdBy).avatar) ||
              '',"nickname":(_vm.getUser(scope.row.createdBy) &&
                _vm.getUser(scope.row.createdBy).nickname) ||
              '',"colorid":scope.row.createdBy}}),_c('span',[_vm._v(_vm._s(_vm.getUser(scope.row.createdBy).nickname))])],1)]}}],null,false,3482147981)}):_vm._e(),(_vm.mode === 'review')?_c('el-table-column',{attrs:{"prop":"createdBy","label":_vm.$t('addMyColla.table.title13'),"min-width":280,"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.createdAt))])]}}],null,false,2267917363)}):_vm._e()],1),(_vm.mode === 'review')?_c('el-pagination',{staticStyle:{"margin-top":"10px"},attrs:{"current-page":_vm.reviewingPageInfo.currentPage + 1,"background":"","page-sizes":[10, 20, 50],"page-size":_vm.reviewingPageInfo.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.reviewingPageInfo.total},on:{"current-change":_vm.changeReviewingPage}}):_vm._e(),_c('el-table',{directives:[{name:"show",rawName:"v-show",value:((_vm.mode === 'confirm') & !_vm.is_show_chart),expression:"(mode === 'confirm') & !is_show_chart"},{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticStyle:{"width":"100%","background":"white"},attrs:{"data":_vm.confirmData,"height":"80vh","header-cell-style":{
      color: '#383838',
    },"cell-style":_vm.changeCellStyle},on:{"sort-change":_vm.hadleChangeRowconfirm}},[(_vm.mode === 'confirm')?_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('changeRequest.title'),"min-width":480,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.jumpToRequestDetail(scope.row)}}},[_vm._v(_vm._s(scope.row.name))])]}}],null,false,3448655335)}):_vm._e(),(_vm.mode === 'confirm')?_c('el-table-column',{attrs:{"prop":"createdBy","label":_vm.$t('addMyColla.table.title3'),"min-width":280},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"display":"flex","align-items":"center","gap":"10px"}},[_c('personal-avatar',{staticStyle:{"justify-content":"left"},attrs:{"placement":'right',"size":33,"avatar":(_vm.getUser(scope.row.createdBy) &&
                _vm.getUser(scope.row.createdBy).avatar) ||
              '',"nickname":(_vm.getUser(scope.row.createdBy) &&
                _vm.getUser(scope.row.createdBy).nickname) ||
              '',"colorid":scope.row.createdBy}}),_c('span',[_vm._v(_vm._s(_vm.getUser(scope.row.createdBy).nickname))])],1)]}}],null,false,3482147981)}):_vm._e(),(_vm.mode === 'confirm')?_c('el-table-column',{attrs:{"prop":"createdBy","label":_vm.$t('addMyColla.table.title13'),"sortable":"custom","min-width":280},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.createdAt))])]}}],null,false,2267917363)}):_vm._e()],1),((_vm.mode === 'confirm') & !_vm.is_show_chart)?_c('el-pagination',{staticStyle:{"margin-top":"10px"},attrs:{"current-page":_vm.confirmPageInfo.currentPage + 1,"background":"","page-sizes":[10, 20, 50],"page-size":_vm.confirmPageInfo.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.confirmPageInfo.total},on:{"current-change":_vm.changeConfirmPage}}):_vm._e(),_c('el-table',{directives:[{name:"show",rawName:"v-show",value:((_vm.mode === 'issue') & !_vm.is_show_chart),expression:"(mode === 'issue') & !is_show_chart"},{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticStyle:{"width":"100%","background":"white"},attrs:{"data":_vm.issueData,"height":"80vh","header-cell-style":{
      color: '#383838',
    },"cell-style":_vm.changeCellStyle},on:{"sort-change":_vm.hadleChangeRowself}},[(_vm.mode === 'issue')?_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('changeRequest.title'),"min-width":280,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.jumpToRequestDetail(scope.row)}}},[_vm._v(_vm._s(scope.row.name))])]}}],null,false,3448655335)}):_vm._e(),(_vm.mode === 'issue')?_c('el-table-column',{attrs:{"prop":"status","label":_vm.$t('addMyColla.table.title2'),"min-width":200},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"pingshen-status-tag",class:{
            status_review: scope.row.status == 'REVIEWING',
            status_merge: scope.row.status == 'MERGED',
            status_approve: scope.row.status == 'APPROVED',
            status_refuse: scope.row.status == 'REFUSED',
          },style:({
            borderStyle: 'solid',
          })},[_vm._v(" "+_vm._s(_vm.matchStatusToName(scope.row.status))+" ")])]}}],null,false,754908622)}):_vm._e(),(_vm.mode === 'issue')?_c('el-table-column',{attrs:{"prop":"createdBy","label":_vm.$t('addMyColla.table.title3'),"min-width":200},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"display":"flex","align-items":"center","gap":"10px"}},[_c('personal-avatar',{staticStyle:{"justify-content":"left"},attrs:{"placement":'right',"size":33,"avatar":(_vm.getUser(scope.row.createdBy) &&
                _vm.getUser(scope.row.createdBy).avatar) ||
              '',"nickname":(_vm.getUser(scope.row.createdBy) &&
                _vm.getUser(scope.row.createdBy).nickname) ||
              '',"colorid":scope.row.createdBy}}),_c('span',[_vm._v(_vm._s(_vm.getUser(scope.row.createdBy).nickname))])],1)]}}],null,false,3482147981)}):_vm._e(),(_vm.mode === 'issue')?_c('el-table-column',{attrs:{"prop":"createdBy","label":_vm.$t('addMyColla.table.title13'),"sortable":"custom","min-width":280},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.createdAt))])]}}],null,false,2267917363)}):_vm._e(),(_vm.mode === 'issue')?_c('el-table-column',{attrs:{"prop":"owner","label":_vm.$t('addMyColla.table.title5'),"min-width":250,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"disabled":scope.row.reviewerList.length ===
            scope.row.reviewerList.length -
              scope.row.unfinishedReviewerList.length,"effect":"dark","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('span',[_vm._v(_vm._s(_vm.$t("addMyColla.table.title6") + _vm.getNickNames(scope.row.unfinishedReviewerList)))])]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openPinShenDialog(scope.row)}}},[_c('span',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(scope.row.reviewerList.length - scope.row.unfinishedReviewerList.length))]),_c('span',{staticStyle:{"margin":"0 4px"}},[_vm._v("/")]),_c('span',[_vm._v(_vm._s(scope.row.reviewerList.length))])])])]}}],null,false,1298967749)}):_vm._e(),(_vm.mode === 'issue')?_c('el-table-column',{attrs:{"prop":"title","label":_vm.$t('DFMEA.More'),"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',[_c('span',{staticClass:"el-dropdown-link",staticStyle:{"color":"#84aace"}},[_vm._v("    "),_c('i',{staticClass:"iconfont icon-a-216gengduo-shuxiang",staticStyle:{"color":"rgb(48, 100, 143)"}}),_vm._v("    ")]),_c('el-dropdown-menu',{staticStyle:{"width":"210px"},attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('div',[_c('div',{class:{
                  noagain: scope.row.status !== 'REVIEWING',
                  operate_button: scope.row.status == 'REVIEWING',
                },on:{"click":function($event){return _vm.sendAgain(scope.row)}}},[_c('i',{staticStyle:{"margin-right":"10px"}},[_c('i',{staticClass:"iconfont icon-fasong"})]),_c('span',[_vm._v(_vm._s(_vm.$t("DFMEA.Reminder")))])])])])],1)]}}],null,false,2223605559)}):_vm._e()],1),((_vm.mode === 'issue') & !_vm.is_show_chart)?_c('el-pagination',{staticStyle:{"margin-top":"10px"},attrs:{"current-page":_vm.sendingPageInfo.currentPage + 1,"background":"","page-sizes":[10, 20, 50],"page-size":_vm.sendingPageInfo.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.sendingPageInfo.total},on:{"current-change":_vm.changeSendingPage}}):_vm._e(),_c('el-dialog',{attrs:{"title":_vm.$t('addNodeDetail.res'),"visible":_vm.pingshenDialog,"close-on-click-modal":false,"show-close":true},on:{"update:visible":function($event){_vm.pingshenDialog=$event}}},[_c('div',{staticStyle:{"height":"600px","overflow":"scroll","margin-bottom":"20px"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isDialogLoading),expression:"isDialogLoading"}],staticStyle:{"width":"100%","background":"white"},attrs:{"data":_vm.pingshenData,"header-cell-style":{
          color: '#383838',
        },"cell-style":_vm.changeCellStyle,"width":"600"}},[_c('el-table-column',{attrs:{"prop":"createdBy","label":_vm.$t('addNodeDetail.people')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"display":"flex","align-items":"center","gap":"10px"}},[_c('personal-avatar',{staticStyle:{"justify-content":"left"},attrs:{"placement":'right',"size":33,"avatar":(_vm.getUser(scope.row.createdBy) &&
                    _vm.getUser(scope.row.createdBy).avatar) ||
                  '',"nickname":(_vm.getUser(scope.row.createdBy) &&
                    _vm.getUser(scope.row.createdBy).nickname) ||
                  '',"colorid":scope.row.createdBy}}),_c('span',[_vm._v(_vm._s(_vm.getUser(scope.row.createdBy).nickname))])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"approved","label":_vm.$t('addNodeDetail.res')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"pingshen-status-tag",class:{
                status_approve: scope.row.approved,
                status_refuse: !scope.row.approved,
              },style:({
                borderStyle: 'solid',
              })},[_vm._v(" "+_vm._s(scope.row.approved ? _vm.$t("changeRequest.approve") : _vm.$t("changeRequest.refuse"))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"message","label":_vm.$t('addNodeDetail.comment')}})],1)],1)]),_c('el-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode === 'all'),expression:"mode === 'all'"},{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticStyle:{"width":"100%","background":"white"},attrs:{"height":"80vh","data":_vm.allData,"header-cell-style":{
      color: '#383838',
    },"cell-style":_vm.changeCellStyle},on:{"sort-change":_vm.hadleChangeRowall}},[(_vm.mode === 'all')?_c('el-table-column',{attrs:{"prop":"nodeKey","label":_vm.$t('changeRequest.id'),"min-width":150,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"cursor":"pointer","color":"rgb(255, 195, 0)","font-weight":"700"},on:{"click":function($event){return _vm.rowClicked(scope.row)}}},[_vm._v(_vm._s(scope.row.nodeKey))])]}}],null,false,2132044022)}):_vm._e(),(_vm.mode === 'all')?_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('changeRequest.title'),"min-width":280,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openRequestDialog(scope.row)}}},[_vm._v(_vm._s(scope.row.name))])]}}],null,false,845597171)}):_vm._e(),(_vm.mode === 'all')?_c('el-table-column',{attrs:{"prop":"status","label":_vm.$t('addMyColla.table.title2'),"min-width":200},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"pingshen-status-tag",class:{
            status_review: scope.row.status == 'REVIEWING',
            status_merge: scope.row.status == 'MERGED',
            status_approve: scope.row.status == 'APPROVED',
            status_refuse: scope.row.status == 'REFUSED',
          },style:({
            borderStyle: 'solid',
          })},[_vm._v(" "+_vm._s(_vm.matchStatusToName(scope.row.status))+" ")])]}}],null,false,754908622)}):_vm._e(),(_vm.mode === 'all')?_c('el-table-column',{attrs:{"prop":"createdBy","label":_vm.$t('addMyColla.table.title3'),"min-width":150},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"display":"flex","align-items":"center","gap":"10px"}},[_c('personal-avatar',{staticStyle:{"justify-content":"left"},attrs:{"placement":'right',"size":33,"avatar":(_vm.getUser(scope.row.createdBy) &&
                _vm.getUser(scope.row.createdBy).avatar) ||
              '',"nickname":(_vm.getUser(scope.row.createdBy) &&
                _vm.getUser(scope.row.createdBy).nickname) ||
              '',"colorid":scope.row.createdBy}}),_c('span',[_vm._v(_vm._s(_vm.getUser(scope.row.createdBy).nickname))])],1)]}}],null,false,3482147981)}):_vm._e(),(_vm.mode === 'all')?_c('el-table-column',{attrs:{"prop":"createdBy","label":_vm.$t('addMyColla.table.title13'),"sortable":"custom","min-width":280},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.createdAt))])]}}],null,false,2267917363)}):_vm._e(),(_vm.mode === 'all')?_c('el-table-column',{attrs:{"prop":"owner","label":_vm.$t('addMyColla.table.title5'),"min-width":200,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"disabled":scope.row.reviewerList.length ===
            scope.row.reviewerList.length -
              scope.row.unfinishedReviewerList.length,"effect":"dark","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('span',[_vm._v(_vm._s(_vm.$t("addMyColla.table.title6") + _vm.getNickNames(scope.row.unfinishedReviewerList)))])]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openPinShenDialog(scope.row)}}},[_c('span',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(scope.row.reviewerList.length - scope.row.unfinishedReviewerList.length))]),_c('span',{staticStyle:{"margin":"0 4px"}},[_vm._v("/")]),_c('span',[_vm._v(_vm._s(scope.row.reviewerList.length))])])])]}}],null,false,1298967749)}):_vm._e()],1),((_vm.mode === 'all') & !_vm.is_show_chart)?_c('el-pagination',{staticStyle:{"margin-top":"10px"},attrs:{"current-page":_vm.allPageInfo.currentPage + 1,"background":"","page-sizes":[10, 20, 50],"page-size":_vm.allPageInfo.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.allPageInfo.total},on:{"current-change":_vm.changeAllPage}}):_vm._e(),_c('el-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_show_chart),expression:"is_show_chart"}],staticStyle:{"position":"absolute","right":"0","top":"6%"},attrs:{"size":"mini"},on:{"change":_vm.updateBarChart},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},_vm._l((_vm.chartOptions),function(item,index){return _c('el-option',{key:index,attrs:{"value":index,"label":_vm.$t('changeRequest.option1') +
        (item.chartType == 'MOST_TIMES_AMOUNT_NODES'
          ? item.amount
          : item.ratio) +
        (item.chartType == 'MOST_TIMES_AMOUNT_NODES'
          ? _vm.$t('changeRequest.option2')
          : _vm.$t('changeRequest.option3'))}},[_vm._v(" "+_vm._s(_vm.$t("changeRequest.option1") + (item.chartType == "MOST_TIMES_AMOUNT_NODES" ? item.amount : item.ratio) + (item.chartType == "MOST_TIMES_AMOUNT_NODES" ? _vm.$t("changeRequest.option2") : _vm.$t("changeRequest.option3")))+" ")])}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_show_chart),expression:"is_show_chart"}],ref:"echarts_main_wrap",staticClass:"echarts_main_wrap",attrs:{"id":"echarts_main_wrap"}},[_c('div',{staticClass:"bar1"},[_c('div',{ref:"bar1",attrs:{"id":"bar1"}})]),_c('div',{staticClass:"bar1"},[_c('div',{ref:"bar2",attrs:{"id":"bar2"}})])]),_c('el-dialog',{attrs:{"title":_vm.pieHeader + _vm.$t('changeRequest.pieHeader') + _vm.$t('changeRequest.title'),"visible":_vm.pieDialog,"close-on-click-modal":false,"show-close":true},on:{"update:visible":function($event){_vm.pieDialog=$event}}},[_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('el-table',{staticStyle:{"width":"100%","background":"white"},attrs:{"data":_vm.pieClickData,"header-cell-style":{
          color: '#383838',
        },"cell-style":_vm.changeCellStyle,"width":"600","height":600}},[_c('el-table-column',{attrs:{"prop":"nodeKey","label":_vm.$t('changeRequest.id'),"width":150,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"cursor":"pointer","color":"rgb(255, 195, 0)","font-weight":"700"},on:{"click":function($event){return _vm.rowClicked(scope.row)}}},[_vm._v(_vm._s(scope.row.nodeKey))])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('changeRequest.title'),"min-width":200,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openRequestDialog(scope.row)}}},[_vm._v(_vm._s(scope.row.name))])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":_vm.$t('addMyColla.table.title2'),"min-width":150},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"pingshen-status-tag",class:{
                status_review: scope.row.status == 'REVIEWING',
                status_merge: scope.row.status == 'MERGED',
                status_approve: scope.row.status == 'APPROVED',
                status_refuse: scope.row.status == 'REFUSED',
              },style:({
                borderStyle: 'solid',
              })},[_vm._v(" "+_vm._s(_vm.matchStatusToName(scope.row.status))+" ")])]}}])})],1),_c('el-pagination',{staticStyle:{"margin-top":"10px"},attrs:{"current-page":_vm.pieDialogPageInfo.currentPage + 1,"background":"","page-sizes":[10, 20, 50],"page-size":_vm.pieDialogPageInfo.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.pieDialogPageInfo.total},on:{"current-change":_vm.changepieDialogPage}})],1)]),_c('change-request-dialog',{attrs:{"merge_visible":_vm.mergeDialog,"change-request-id":_vm.mergeId,"reviewer-list-all":_vm.reviewerList,"node-key":_vm.mergeNodeKey},on:{"closeDialog":_vm.closeRequestDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }